import { Box, Typography } from '@mui/material';
import type { GetStaticProps } from 'next';
import Layout from '@/components/others/Layout/Layout';
import PageLayout from '@/components/others/PageLayout/PageLayout';
import { getStrapiPage, StrapiModelName } from '@/lib/strapi/strapi.api';
import { StrapiLandingPage } from '@/lib/strapi/strapi.types';
import { LandingPageStyles } from '@/lib/styles/pages/landing-page.styles';
import { useStyles } from '@/lib/styles/styles.hooks';
import { CSMPage } from '@/lib/utils/page.types';
import HeadingGroup from '@/components/molecules/HeadingGroup/HeadingGroup';
import React, { Key } from 'react';
import AppImage from '@/components/atoms/AppImage/AppImage';
import Arrow3 from '@/public/icons/Arrow3.svg';
import { useRedirectOn } from '@/lib/aws/auth/auth.hooks';
import { AuthState } from '@/lib/store/auth/auth.utils';
import { AppRoute } from '@/_routes';

const lads: any[] = [
  {
    letter: 'A',
    headingGroup: {
      id: 1,
      title: 'Recruit Your Talent',
      titleVariant: 'h2',
      body: 'Acquire talent to train & complete your star-studded roster to win majors with.',
    },
  },
  {
    letter: 'B',
    headingGroup: {
      id: 1,
      title: 'Ultimate Strategist',
      titleVariant: 'h2',
      body: 'Both in the game and outside of it. Match your roster to their strenghts on each map, select the right sponsors, staff and be selective of which competitions you partake in.',
    },
  },
  {
    letter: 'C',
    headingGroup: {
      id: 1,
      title: 'Climb the Ranks',
      titleVariant: 'h2',
      body: 'Compete to rank up through divisions to top the leaderboards and gain the best rewards.',
    },
  },
];
const Home: CSMPage<StrapiLandingPage> = ({ layoutData }) => {
  const styles = useStyles(LandingPageStyles, {});

  useRedirectOn([AuthState.AUTHORIZED], AppRoute.DASHBOARD);

  // const fields: StrapiInputField[] = [
  //   {
  //     id: 0,
  //     name: 'name',
  //     label: '',
  //     required: true,
  //     placeholder: 'youremail.gg',
  //     type: 'text',
  //   },
  // ];

  // const handleValid: any = async () => {
  //   console.log(`[SendEmail] handleErrors:: `);
  // };

  // const handleErrors: any = (errors) => {
  //   console.log(`[SendEmail] handleErrors:: `, errors);
  //   // showAlertMessages('error', pageData.alertMessages.errorMessage);
  // };

  return (
    <Layout layoutData={layoutData}>
      <Box sx={styles.root} className={'root'}>
        <Box sx={styles.earlyRegContainer}>
          <PageLayout>
            <Box sx={styles.earlyRegTextForm}>
              <HeadingGroup
                headingGroupData={{
                  id: 0,
                  title: 'Manage Your Own Counter-Strike Team and Lead it to Glory.',
                  titleVariant: 'h1',
                  subhead: 'Early Access',
                  // body: 'Register now for a chance to play the game before everyone else!',
                }}
              />
              {/* <Box sx={styles.earlyRegForm}>
                <CreateEarlyRegister
                  onSubmit={handleValid}
                  onSubmitError={handleErrors}
                  fields={fields}
                  formButton={{
                    id: 0,
                    label: 'Start Your Legacy',
                    variant: 'contained',
                  }}
                />
              </Box> */}
            </Box>

            <AppImage
              src={'/images/cursor_interactive_imagery_01_new.png'}
              alt="alt"
              ratioWidth={3}
              ratioHeight={2}
              sx={styles.image}
            />
          </PageLayout>
        </Box>
        <PageLayout>
          <HeadingGroup
            headingGroupData={{
              id: 0,
              title: "Let's have it, lads!",
              titleVariant: 'h1',
              body: 'to share big wins, chat tips, and be a part of the CSTactics journey.',
            }}
          />
          <Box sx={styles.ladsLetterText}>
            {lads?.map((lad: any, id: Key | null | undefined) => (
              <Box key={id}>
                <Box sx={styles.arrowLetter}>
                  <Typography variant="body" sx={styles.letter}>
                    {' '}
                    {lad.letter}
                  </Typography>
                  <Arrow3 />
                </Box>
                <HeadingGroup sx={styles.textLad} headingGroupData={lad.headingGroup} />
              </Box>
            ))}
          </Box>
        </PageLayout>
        <Box sx={styles.bottomPart}>
          <PageLayout>
            <Box sx={styles.textWithLink}>
              {/* <Discord /> */}
              <HeadingGroup
                headingGroupData={{
                  id: 0,
                  title: 'Challenge Players From all Around the World and Rank Up!',
                  titleVariant: 'h1',
                  subhead: 'Join Us!',
                }}
              />
              {/* <Box sx={styles.textLink}>
                <AppLink
                  sx={styles.inline}
                  label={'Join the Discord'}
                  variant="text"
                  textVariant="body"
                  hasArrowIcon={false}
                  route={'https://discord.com/invite/Mck87JSn'}
                />
                <Typography variant="body">&nbsp;</Typography>
                <Typography variant="body">
                  to share big wins, chat tips, and be a part of the CSTactics journey.
                </Typography>
              </Box> */}
            </Box>
            <AppImage src={'/images/cursor_interactive_imagery_03_new.png'} alt="alt" ratioWidth={3} ratioHeight={2} />
          </PageLayout>
        </Box>
      </Box>
    </Layout>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return await getStrapiPage<StrapiLandingPage>(StrapiModelName.LandingPage, { locale });
};

export default Home;
