import { SystemStyleObject, Theme } from '@mui/system';
import { ComponentStylesWithFns } from '../styles.types';
import { palette } from '@/theme/palette';

const styles: ComponentStylesWithFns = {
  root: {
    width: '100%',
    height: '100%',
  },
  mainContainerInputPage: {
    width: '100%',
    boxSizing: 'border-box',

    display: 'grid',
    gridTemplateColumns: ['1fr', '1fr', '1fr 1fr', '2fr 1fr'],
    marginBottom: [7],
    marginTop: [4],
  },
  mainContainerInputPageContentRight: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-start',
    paddingBottom: [7],
  },
  mainContainerInputPageContentRightMobile: {
    backgroundColor: 'bgPrimary.primary',
    paddingY: [4, 6],
    display: ['flex', 'flex', 'none'],
    flexDirection: 'column',
    alignItems: 'center',
  },
  formHeading: {
    height: 'fit-content',
  },
  formHeadingSubtext: {
    height: 'fit-content',
  },
  mainContainerInputPageContentLeft: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  mainContainerAccount: {
    width: '100%',
    boxSizing: 'border-box',

    display: 'grid',
    gridTemplateColumns: ['1fr', '1fr', '1fr 1fr', '1fr 2fr'],
    marginBottom: [7],
    marginTop: [4],
  },
  mainContainerAccountContentRight: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: [4],
  },
  mainContainerAccountContentLeft: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingBottom: [4],
  },
  accountSettingForm: {
    display: 'grid',
    gridTemplateColumns: ['1fr', '1fr', '1fr', '1fr 2fr'],
  },
  mainContainerPromotion: {
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    height: '100%',
    width: '100%',
    position: 'relative',
  },
  backgroundOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(180deg, rgba(17, 24, 39, 0) 0%, #111827 100%)',
    zIndex: 300,
  },
  promotionImage: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
};

const getPageHeaderImageStyles: (imageUrl: string) => SystemStyleObject<Theme> | false = (imageUrl) => {
  if (!imageUrl) return false;

  return {
    height: '100%',
    width: '100%',
    backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, ${palette.bgPrimary.primary} 100%), url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };
};

export { styles as PageStyles, getPageHeaderImageStyles };
