import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useAuthStore } from '../../store/auth/auth.store';
import { AuthState } from '../../store/auth/auth.utils';
import { AppRoute } from '../../../_routes';

/**
 * Redirect app if app state is or changed to one of give {@link AuthState}
 * @param authStates - {@link AuthState}s to redirect on
 * @param to - Route to Redirect to
 */
export function useRedirectOn(authStates: AuthState[], to: AppRoute): void {
  const router = useRouter();

  const { authState } = useAuthStore((state) => ({ authState: state.authState }));

  useEffect(() => {
    if (authStates.includes(authState)) {
      router.push(to);
    }
  }, [authState]);
}
